.qld-table {

    border: 1px solid var(--qld-light-grey);
    border-radius: 0.75rem;
    margin-bottom: 1rem;

    // DS includes a version of the table without borders ("quiet table").
    // This is achieved by setting the border on a wrapping .qld-table--borderless class
    &--borderless {
        border: 1px solid transparent;
    }

    .table {
        --qld-table-border-color: var(--qld-light-grey);
        --qld-table-header-color: var(--qld-light-text-heading);
        --qld-table-cell-color: var(--qld-light-text-text);
        --qld-table-striped-bg: var(--qld-extra-light-grey);

        margin-bottom: 0;
        border-radius: 0.75rem;

        //required to maintain effective border-radius on the table
        overflow: hidden;

        caption {
            caption-side: top;
            color: var(--qld-headings-color);
            padding: 0.75rem;
            line-height: 2rem;
            font-weight: 600;
            font-size: 1.25rem;
            border-bottom: 1px solid var(--qld-table-border-color);
        }

        .sub-caption {
            color: var(--qld-dark-grey-muted);
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.25rem;
            display: table-row;
        }

        thead th,
        thead td {
            border-bottom: 2px solid var(--qld-brand-secondary);
        }

        th {
            color: var(--qld-table-header-color);
            padding: 1.25rem 0.75rem; 
        }

        td {
            color: var(--qld-table-cell-color);
            padding: 0.75rem; 
        }

        tbody tr {
            border-bottom: 1px solid var(--qld-table-border-color);
        }

        tfoot th,
        tfoot td {
            border-top: 2px solid $qld-brand-secondary;
            border-bottom: 0;
            font-weight: 600;
            padding: 0.75rem;
        }

    }


    //Build a bootstrap ".table-dark" class dynamically, passing in a background colour
    @include table-variant("dark", #09549F);

    //Then, we extend .table-dark with some additional rules 
    .table-dark {
        --qld-table-border-color: var(--qld-dark-border);
        --qld-table-header-color: var(--qld-white);
        --qld-table-cell-color: var(--qld-white);
        --qld-table-striped-bg: var(--qld-extra-light-grey);
        
        caption,
        .sub-caption {
            background-color: #09549F;
            color: #FFF;
        }

        thead th {
            background-color: #04498F;
        }
    }
}