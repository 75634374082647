// ----------------------------------------------------------------------------------------------------------------------
// Header - palettes and second hand variables:
@import "./colours";

/*
 * Mobile layout
 */
.qld-quick-exit {
  --#{$prefix}quick-exit-spacing: 1rem;
  --#{$prefix}quick-exit-gaps: 25px;
  --#{$prefix}quick-exit-text-outline: 2px;
  --#{$prefix}quick-exit-text-outline-offset: 2px;
  --#{$prefix}tooltip-width: 190px;
  --#{$prefix}tooltip-arrow: 8px;
  --#{$prefix}tooltip-desktop-top-offset: 40px;
  --#{$prefix}icon-info-spacing: 40px;
  --#{$prefix}quick-exit-actions-right-padding: 9px;

  background: var(--#{$prefix}quick-exit-bg);
  color: var(--#{$prefix}quick-exit-text-colour);
  inset-inline-start: 0;
  inset-block-end: 0;
  padding-block: var(--#{$prefix}quick-exit-spacing);
  padding-inline: var(--#{$prefix}gutter-x);
  position: fixed;
  width: 100%;
  z-index: 200;

  &-inner {
    display: flex;
    align-items: center;
    height: 100%;

    &.container-fluid {
      gap: calc(var(--#{$prefix}body-font-size) * 1.25);
    }
    .qld-quick-exit-input:checked + .qld-tooltip-parent .qld-tooltip-wrapper {
      display: block;
    }
    .qld-quick-exit-input:checked + .qld-quick-exit-label {
      color: var(--#{$prefix}quick-exit-text-colour);
      font-weight: 500;
    }
    .qld-tooltip {
      width: var(--#{$prefix}tooltip-width);
    }
    .qld-tooltip-parent {
      position: relative;
    }
    .qld-tooltip-wrapper {
      background-color: var(--#{$prefix}quick-exit-tooltip-bg);
      inset-block-end: calc(var(--#{$prefix}tooltip-desktop-top-offset) + 9px);
      box-shadow: 0 0 8px 0 var(--#{$prefix}quick-exit-tooltip-box-shadow);
      color: var(--#{$prefix}quick-exit-tooltip-text);
      display: none;
      font-weight: 500;
      padding: var(--#{$prefix}quick-exit-gaps);
      position: absolute;
    }
    .qld-tooltip-wrapper:after {
      border-block-end: var(--#{$prefix}tooltip-arrow) solid var(--#{$prefix}quick-exit-tooltip-bg);
      border-inline-start: var(--#{$prefix}tooltip-arrow) solid var(--#{$prefix}quick-exit-tooltip-bg);
      border-inline-end: var(--#{$prefix}tooltip-arrow) solid transparent;
      border-block-start: var(--#{$prefix}tooltip-arrow) solid transparent;
      inset-block-end: calc(var(--#{$prefix}tooltip-arrow) * -1);
      content: " ";
      position: absolute;
      inset-inline-start: var(--#{$prefix}quick-exit-gaps);
      transform: rotate(-45deg);
    }
    .qld-quick-exit-item.qld-tooltip-container {
      text-decoration: underline;
      &:focus,
      &:active,
      &:focus-within {
        outline-offset: var(--#{$prefix}quick-exit-text-outline-offset);
        box-shadow: none;
        outline: var(--#{$prefix}quick-exit-text-outline) solid var(--#{$prefix}quick-exit-tooltip-btn-focus);
        border-radius: 0;
      }
    }
    .qld-quick-exit-label,
    .qld-tooltip-prompt {
      font-weight: 500;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-underline-offset: calc(var(--#{$prefix}quick-exit-text-outline-offset) * 2);
      &:hover {
        text-decoration-thickness: var(--#{$prefix}link-underline-thickness-hover);
      }
    }
    .qld-tooltip-prompt {
      text-wrap: nowrap;
    }
    .qld-quick-exit-list {
      margin: 0;
      padding: 0;
      list-style: none;
      -ms-flex-align: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      height: 100%;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      gap: var(--#{$prefix}quick-exit-gaps);
    }
    .qld-quick-exit-actions {
      -ms-flex-positive: 1;
      flex-grow: 0;
    }
    .qld-quick-exit-tip-title {
      font-size: calc(var(--#{$prefix}body-font-size) * 0.875);
      margin-block-end: 0;
    }
    .qld-quick-exit-button {
      border-color: var(--#{$prefix}quick-exit-button-bg-colour);
      background-color: var(--#{$prefix}quick-exit-button-bg-colour);
      color: var(--#{$prefix}quick-exit-button-text-colour);
      &:hover {
        border-color: var(--#{$prefix}quick-exit-button-bg-colour-hover);
        background-color: var(--#{$prefix}quick-exit-button-bg-colour-hover);
      }
    }
    a.qld-quick-exit-tip-link {
      color: var(--#{$prefix}quick-exit-link-text-colour);
      &:visited {
        text-decoration-color: var(--#{$prefix}quick-exit-text-colour);
      }
      &:hover,
      &:focus {
        text-decoration-thickness: var(--#{$prefix}link-underline-thickness-hover);
      }
    }
    .qg-quick-exit__tips {
      flex-grow: 1;
    }
    &.has-icon-info .qg-quick-exit__tips {
      position: relative;
      display: block;
    }
  }
  .icon-info {
    position: absolute;
    inset-inline-start: 0;
    background-repeat: no-repeat;
    margin-block-start: 4px;
    background-image: url("data:image/svg+xml,<svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M9 0.25C4.00781 0.25 0 4.29297 0 9.25C0 14.2422 4.00781 18.25 9 18.25C13.957 18.25 18 14.2422 18 9.25C18 4.29297 13.957 0.25 9 0.25ZM9 17.125C4.64062 17.125 1.125 13.6094 1.125 9.25C1.125 4.92578 4.64062 1.375 9 1.375C13.3242 1.375 16.875 4.92578 16.875 9.25C16.875 13.6094 13.3242 17.125 9 17.125ZM9 6.71875C9.45703 6.71875 9.84375 6.36719 9.84375 5.875C9.84375 5.41797 9.45703 5.03125 9 5.03125C8.50781 5.03125 8.15625 5.41797 8.15625 5.875C8.15625 6.36719 8.50781 6.71875 9 6.71875ZM10.6875 12.625H9.5625V8.6875C9.5625 8.40625 9.28125 8.125 9 8.125H7.875C7.55859 8.125 7.3125 8.40625 7.3125 8.6875C7.3125 9.00391 7.55859 9.25 7.875 9.25H8.4375V12.625H7.3125C6.99609 12.625 6.75 12.9062 6.75 13.1875C6.75 13.5039 6.99609 13.75 7.3125 13.75H10.6875C10.9688 13.75 11.25 13.5039 11.25 13.1875C11.25 12.9062 10.9688 12.625 10.6875 12.625Z' fill='white'/></svg>");
  }
  @include media-breakpoint-up(lg) {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    inset-block-start: 0;
    z-index: 999;
    padding-block: 20px;
    padding-inline: 40px;
    .container {
      padding-inline: calc(var(--#{$prefix}gutter-x) * 0.5);
    }
    &-inner {
      justify-content: space-between;
      .qld-quick-exit-tip-link {
        -ms-flex-align: center;
        align-items: center;
        color: var(--#{$prefix}quick-exit-text-colour);
        display: -ms-flexbox;
        display: flex;
      }
      &.container-fluid {
        padding-inline: calc(var(--#{$prefix}gutter-x) * 0.333);
      }
      .qld-quick-exit-tip-title {
        font-size: var(--#{$prefix}body-font-size);
      }
      .qld-quick-exit-actions {
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
      .qld-tooltip-wrapper {
        inset-block-end: auto;
        inset-block-start: var(--#{$prefix}tooltip-desktop-top-offset);
        padding: var(--#{$prefix}quick-exit-spacing);
      }
      .qld-tooltip-wrapper:after {
        border-block-end: var(--#{$prefix}tooltip-arrow) solid transparent;
        border-inline-start: var(--#{$prefix}tooltip-arrow) solid transparent;
        border-inline-end: var(--#{$prefix}tooltip-arrow) solid var(--#{$prefix}quick-exit-tooltip-bg);
        border-block-start: var(--#{$prefix}tooltip-arrow) solid var(--#{$prefix}quick-exit-tooltip-bg);
        inset-block-end: auto;
        inset-inline-start: 25px;
        inset-block-start: calc(var(--#{$prefix}tooltip-arrow) * -1);
      }
      .not-mobile,
      .qld-quick-exit-tip-link {
        display: inline;
      }
    }
  }
  .qld-quick-exit-inner.has-tooltip {
    .qg-quick-exit__tips {
      padding-inline-start: 0;
    }
    @include media-breakpoint-down(lg) {
      .not-mobile {
        display: none;
      }
    }
    @include media-breakpoint-down(sm) {
      .very-small-mobile {
        display: none;
      }
    }
  }
  .qld-quick-exit-inner.has-icon-info {
    .qg-quick-exit__tips {
      padding-inline-start: calc(var(--#{$prefix}icon-info-spacing) * 1);
    }
  }
  .qld-quick-exit-inner.has-icon-info.has-tooltip {
    @include media-breakpoint-down(lg) {
      .not-mobile {
        display: none;
      }
      .not-mobile,
      .qld-quick-exit-tip-link {
        display: none;
      }
      .icon-info {
        margin-block-start: 0;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .qld-quick-exit-inner {
      .qg-quick-exit__tips {
        .not-mobile,
        a {
          display: none;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .qld-quick-exit-button {
      padding-inline: 10px;
    }
  }
}
