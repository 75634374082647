.qld-quick-exit {
  --#{$prefix}quick-exit-bg: var(--#{$prefix}color-default-color-dark-background-default);
  --#{$prefix}quick-exit-text-colour: var(--#{$prefix}color-default-color-dark-text-default);
  --#{$prefix}quick-exit-link-text-colour: var(--#{$prefix}color-default-color-dark-link-default);
  --#{$prefix}quick-exit-button-bg-colour: var(--#{$prefix}color-default-color-dark-action-secondary);
  --#{$prefix}quick-exit-button-bg-colour-hover: var(--#{$prefix}color-default-color-dark-action-secondary-hover);
  --#{$prefix}quick-exit-button-text-colour: var(--#{$prefix}color-default-color-dark-link-on-action);

  --#{$prefix}quick-exit-tooltip-bg: var(--#{$prefix}core-default-color-neutral-white);
  --#{$prefix}quick-exit-tooltip-btn-focus: var(--#{$prefix}color-default-color-light-border-alt);
  --#{$prefix}quick-exit-tooltip-text: var(--#{$prefix}color-default-color-light-text-default);
  --#{$prefix}quick-exit-tooltip-box-shadow: var(--#{$prefix}color-default-color-light-border-default);

  .dark &,
  .dark-alt & {
    --#{$prefix}quick-exit-bg: var(--#{$prefix}color-default-color-dark-background-alt);
    --#{$prefix}quick-exit-text-colour: var(--#{$prefix}color-default-color-dark-text-default);
    --#{$prefix}quick-exit-link-text-colour: var(--#{$prefix}color-default-color-dark-link-default);
    --#{$prefix}quick-exit-button-bg-colour: var(--#{$prefix}color-default-color-dark-action-secondary);
    --#{$prefix}quick-exit-button-bg-colour-hover: var(--#{$prefix}color-default-color-dark-action-secondary-hover);
    --#{$prefix}quick-exit-button-text-colour: var(--#{$prefix}color-default-color-dark-link-on-action);

    --#{$prefix}quick-exit-tooltip-bg: var(--#{$prefix}core-default-color-neutral-white);
    --#{$prefix}quick-exit-tooltip-btn-focus: var(--#{$prefix}color-default-color-light-border-alt);
    --#{$prefix}quick-exit-tooltip-text: var(--#{$prefix}color-default-color-light-text-default);
    --#{$prefix}quick-exit-tooltip-box-shadow: var(--#{$prefix}color-default-color-light-border-default);

    --#{$prefix}link-visited-dark: var(--#{$prefix}core-default-color-neutral-white);
  }
}
