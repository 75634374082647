// ------------------------------------------------------------------------------------------------------------------
//  1. Basic icons for navbar component
// ------------------------------------------------------------------------------------------------------------------
.navbar {
    .dropdown-toggle {
        .toggle_icon {
            color: var(--#{$prefix}navbar-svg-color);
            height: 1rem;
            width: 1rem;
            margin-left: .25rem;
            vertical-align: middle;
            -ms-flex-item-align: center;
            align-self: center;

            //Transitions for navbar
            -webkit-transition: -webkit-transform .25s ease-in;
            transition: -webkit-transform .25s ease-in;
            transition: transform .25s ease-in;
            transition: transform .25s ease-in, -webkit-transform .25s ease-in;

            @include media-breakpoint-down(lg) {
                height: 1.25rem;
                width: 1.25rem;
                // TO DO: This should be cleaner organised
                float: right;
                margin: .5rem;
            }

            @include media-breakpoint-down(md) {
                height: 1rem;
                width: 1rem;
            }
        }

        &.show svg {
            transform: rotate(180deg);
        }
    }

    .dropdown-menu {
        &__inner {
            .chevron__icon {
                height: 1.25rem;
                width: 1.25rem;
                color: var(--#{$prefix}navbar-svg-color);
            }

            a.dropdown-item {
                position: relative;
                .chevron__icon {
                    position: absolute;
                    top: 1.15rem;
                    right: 0;
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .chevron__icon {
            display: none;
        }
    }
}