.global-alert {
    $chevron-icon-white: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27 15.6081C27 16.1011 26.5897 16.4845 26.1282 16.4845L6.79487 16.4845L13.9744 24.7008C14.2821 25.0295 14.2821 25.5772 13.9231 25.9059C13.7692 26.0702 13.5641 26.125 13.359 26.125C13.1026 26.125 12.8974 26.0702 12.7436 25.8511L4.23077 16.2106C3.92308 15.882 3.92308 15.389 4.23077 15.0603L12.7436 5.41981C13.0513 5.03639 13.5641 5.03639 13.9231 5.36504C14.2821 5.69369 14.2821 6.24145 13.9744 6.5701L6.79487 14.7317L26.1282 14.7317C26.5897 14.7317 27 15.1699 27 15.6081Z" fill="#{$qld-white}"/></svg>');
    $chevron-icon-grey: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27 15.6081C27 16.1011 26.5897 16.4845 26.1282 16.4845L6.79487 16.4845L13.9744 24.7008C14.2821 25.0295 14.2821 25.5772 13.9231 25.9059C13.7692 26.0702 13.5641 26.125 13.359 26.125C13.1026 26.125 12.8974 26.0702 12.7436 25.8511L4.23077 16.2106C3.92308 15.882 3.92308 15.389 4.23077 15.0603L12.7436 5.41981C13.0513 5.03639 13.5641 5.03639 13.9231 5.36504C14.2821 5.69369 14.2821 6.24145 13.9744 6.5701L6.79487 14.7317L26.1282 14.7317C26.5897 14.7317 27 15.1699 27 15.6081Z" fill="#{$qld-text-grey}"/></svg>');

    --chevron-icon-white: #{escape-svg($chevron-icon-white)};
    --chevron-icon-grey: #{escape-svg($chevron-icon-grey)};

    .qld-global-alert-main {
        display: flex;

        .global-alert-icon {
            line-height: 1;
            width: 22px;
            height: 22px;
            display: inline-block;
            transition: fill 0.3s ease;
            vertical-align: middle;
            font-size: 1.5rem;
            padding-left: 1.5rem; // padding between icon and text. Added due to bug when content has 3 lines of text.

        }
        .global-alert-content {
            padding: 0 1.25rem 0 1rem;
            .global-alert-action a {
                display: flex;
                align-items: center;
                &:after {
                    content: "";
                    display: inline-block;
                    width: 2rem;
                    height: 2rem;
                    background-repeat: no-repeat;
                    transform: rotate(180deg) scale(0.8);
                }
            }
        }
        .global-alert-close {
            margin-left: auto;
            .btn-close {
                height: 2rem;
                width: 2rem;        
                --#{$prefix}btn-close-opacity: 1;
                background: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:multiply'%3E%3Ccircle cx='16' cy='16' r='16' fill='%23131212' fill-opacity='0.1' /%3E%3C/g%3E%3Cpath d='M17.2871 15.9004L21.3105 11.916L22.1309 11.0957C22.248 10.9785 22.248 10.7832 22.1309 10.627L21.2715 9.76758C21.1152 9.65039 20.9199 9.65039 20.8027 9.76758L15.998 14.6113L11.1543 9.76758C11.0371 9.65039 10.8418 9.65039 10.6855 9.76758L9.82617 10.627C9.70898 10.7832 9.70898 10.9785 9.82617 11.0957L14.6699 15.9004L9.82617 20.7441C9.70898 20.8613 9.70898 21.0566 9.82617 21.2129L10.6855 22.0723C10.8418 22.1895 11.0371 22.1895 11.1543 22.0723L15.998 17.2285L19.9824 21.252L20.8027 22.0723C20.9199 22.1895 21.1152 22.1895 21.2715 22.0723L22.1309 21.2129C22.248 21.0566 22.248 20.8613 22.1309 20.7441L17.2871 15.9004Z' fill='%23131212'/%3E%3C/svg%3E") no-repeat center center;
            }
        }
    }
    &.global-alert-critical {
        background-color: $qld-notify-warning;
        color: $qld-white;
        font-size: 0.875em;
        .global-alert-icon {
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 8.0001V13.0001M12 16.0001H12.01M8.84751 20.6604H15.1525C15.8255 20.6604 16.1621 20.6604 16.4633 20.5625C16.7299 20.4759 16.9753 20.3342 17.1835 20.1466C17.419 19.9347 17.5872 19.6432 17.9237 19.0604L21.0762 13.6001C21.4128 13.0172 21.581 12.7258 21.6469 12.4159C21.7052 12.1418 21.7052 11.8584 21.6469 11.5843C21.581 11.2744 21.4128 10.983 21.0762 10.4001L17.9237 4.93984C17.5872 4.35696 17.419 4.06551 17.1835 3.85355C16.9753 3.66601 16.7299 3.52434 16.4633 3.43773C16.1621 3.33984 15.8255 3.33984 15.1525 3.33984H8.84751C8.17445 3.33984 7.83792 3.33984 7.53665 3.43773C7.27009 3.52434 7.02471 3.66601 6.81643 3.85355C6.58102 4.06551 6.41276 4.35696 6.07623 4.93984L2.92375 10.4001C2.58722 10.983 2.41895 11.2744 2.35309 11.5843C2.29482 11.8584 2.29482 12.1418 2.35309 12.4159C2.41895 12.7258 2.58722 13.0172 2.92375 13.6001L6.07623 19.0604C6.41276 19.6432 6.58102 19.9347 6.81643 20.1466C7.02471 20.3342 7.27009 20.4759 7.53665 20.5625C7.83792 20.6604 8.17445 20.6604 8.84751 20.6604Z' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
        .global-alert-close {
            margin-left: auto;
            .btn-close {
                height: 2rem;
                width: 2rem;        
                --#{$prefix}btn-close-opacity: 1;
                background: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg style='mix-blend-mode:multiply'%3E%3Ccircle cx='16' cy='16' r='16' fill='%23131212' fill-opacity='0.1' /%3E%3C/g%3E%3Cpath d='M17.2871 15.9004L21.3105 11.916L22.1309 11.0957C22.248 10.9785 22.248 10.7832 22.1309 10.627L21.2715 9.76758C21.1152 9.65039 20.9199 9.65039 20.8027 9.76758L15.998 14.6113L11.1543 9.76758C11.0371 9.65039 10.8418 9.65039 10.6855 9.76758L9.82617 10.627C9.70898 10.7832 9.70898 10.9785 9.82617 11.0957L14.6699 15.9004L9.82617 20.7441C9.70898 20.8613 9.70898 21.0566 9.82617 21.2129L10.6855 22.0723C10.8418 22.1895 11.0371 22.1895 11.1543 22.0723L15.998 17.2285L19.9824 21.252L20.8027 22.0723C20.9199 22.1895 21.1152 22.1895 21.2715 22.0723L22.1309 21.2129C22.248 21.0566 22.248 20.8613 22.1309 20.7441L17.2871 15.9004Z' fill='%23F5F5F5'/%3E%3C/svg%3E") no-repeat center center;
            }
        }
        .global-alert-action a {
            color: $qld-white;
            text-decoration-color: $qld-white;
            &:after {
                background: var(--chevron-icon-white);
            }
        }
        & + & {
            border-top: 1px solid $qld-red-border;
        }
    }
    &.global-alert-warning {
        background-color: $qld-notify-alert;
        color: $qld-text-grey;
        font-size: 0.875em;
        .global-alert-icon {
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_5702_80802' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='2' width='20' height='17'%3E%3Cpath d='M10 13.9062C10.457 13.9062 10.8438 14.293 10.8086 14.75C10.8086 15.2422 10.457 15.5938 10 15.5938C9.54297 15.5938 9.15625 15.2422 9.15625 14.75C9.15625 14.293 9.50781 13.9062 10 13.9062ZM10 12.5C9.68359 12.5 9.4375 12.2539 9.4375 11.9375V6.875C9.4375 6.59375 9.71875 6.3125 10 6.3125C10.2461 6.3125 10.5273 6.59375 10.5273 6.875V11.9375C10.5273 12.2539 10.2812 12.5 10 12.5ZM18.7188 15.2422C19.0703 15.8398 19.0703 16.543 18.7188 17.1406C18.3672 17.7734 17.7344 18.125 17.0312 18.125H2.96875C2.23047 18.125 1.59766 17.7734 1.24609 17.1406C0.894531 16.543 0.894531 15.8398 1.24609 15.2422L8.27734 3.35938C8.62891 2.76172 9.26172 2.375 10 2.375C10.7031 2.41016 11.3359 2.76172 11.6875 3.35938L18.7188 15.2422ZM17.7344 16.5781C17.9102 16.332 17.875 16.0508 17.7344 15.8047L10.7031 3.92188C10.5625 3.67578 10.2812 3.53516 10 3.5C9.96484 3.5 10 3.5 10 3.5C9.68359 3.5 9.40234 3.67578 9.26172 3.92188L2.23047 15.8047C2.08984 16.0508 2.05469 16.332 2.23047 16.5781C2.37109 16.8594 2.65234 17 2.96875 17H16.9961C17.3125 17 17.5938 16.8594 17.7344 16.5781Z' fill='%2303213F'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_5702_80802)'%3E%3Crect width='20' height='20' fill='%231D1D1D'/%3E%3C/g%3E%3C/svg%3E%0A");
        }
        .global-alert-action a {
            color: $qld-text-grey;
            &:after {
                background: var(--chevron-icon-grey);
            }
        }
        & + & {
            border-top: 1px solid $qld-yellow-border;
        }
    }
    &.global-alert-info {
        background-color: $qld-general-info-alert;
        color: $qld-text-grey;
        font-size: 0.875em;
        .global-alert-icon {
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_5702_82508' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='1' y='1' width='18' height='18'%3E%3Cpath d='M10 7.20312C9.48047 7.20312 9.10938 6.83203 9.10938 6.3125C9.10938 5.83008 9.48047 5.42188 10 5.42188C10.4824 5.42188 10.8906 5.83008 10.8906 6.3125C10.8906 6.83203 10.4824 7.20312 10 7.20312ZM15.9375 1.5625C17.2363 1.5625 18.3125 2.63867 18.3125 3.9375V15.8125C18.3125 17.1484 17.2363 18.1875 15.9375 18.1875H4.0625C2.72656 18.1875 1.6875 17.1484 1.6875 15.8125V3.9375C1.6875 2.63867 2.72656 1.5625 4.0625 1.5625H15.9375ZM17.125 15.8125V3.9375C17.125 3.30664 16.5684 2.75 15.9375 2.75H4.0625C3.39453 2.75 2.875 3.30664 2.875 3.9375V15.8125C2.875 16.4805 3.39453 17 4.0625 17H15.9375C16.5684 17 17.125 16.4805 17.125 15.8125ZM11.7812 13.4375C12.0781 13.4375 12.375 13.7344 12.375 14.0312C12.375 14.3652 12.0781 14.625 11.7812 14.625H8.21875C7.88477 14.625 7.625 14.3652 7.625 14.0312C7.625 13.7344 7.88477 13.4375 8.21875 13.4375H9.40625V9.875H8.8125C8.47852 9.875 8.21875 9.61523 8.21875 9.28125C8.21875 8.98438 8.47852 8.6875 8.8125 8.6875H10C10.2969 8.6875 10.5938 8.98438 10.5938 9.28125V13.4375H11.7812Z' fill='%2303213F'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_5702_82508)'%3E%3Crect width='20' height='20' fill='%231D1D1D'/%3E%3C/g%3E%3C/svg%3E%0A");
        }
        .global-alert-action a {
            color: $qld-text-grey;
            &:after {
                background: var(--chevron-icon-grey);
            }
        }
        & + & {
            border-top: 1px solid $qld-blue-border;
        }
    }
}

@media (min-width: 768px) { // md and above
    .global-alert {
        .qld-global-alert-main {
            padding: 1rem 1rem;
            .global-alert-content {
                padding-left: 1rem;
                display: flex;
                align-items: center;
            }
        }
        .global-alert-message {
            padding: 0 3rem 0 1rem; // Also: 0 48px 0 16px, according to Health DS
            max-width: 80%;// Added to prevent Label from collapsing
        }
        .global-alert-icon {
            // temporary change to align icon with content
            align-self: center;
        }
        .global-alert-content {
            flex-direction: row !important;
        }
        .global-alert-action {
            margin-left: 2rem;
        }
    }

}

@media (max-width: 767.98px) { // sm and below
    .global-alert {
        .qld-global-alert-main {
            padding: 0.75rem 1rem; // Also 12px 16px
        }
        .global-alert-content {
            padding: 0 1rem 0 0.75rem; // Also 0 16px 0 12px
        }
    }
    
    .global-alert-icon {
        // aligning with text
        padding-top: 0.75rem;
    }
}

@include media-breakpoint-up(lg) { // 992px and above

    .global-alert {
        &.global-alert-critical {
        border-top: 0.063rem solid var(--#{$prefix}core-default-color-status-error-darker);
        }
        &.global-alert-warning {
            border-top: 0.063rem solid var(--#{$prefix}core-default-color-status-caution-darker);
        }
        &.global-alert-info {
            border-top: 0.063rem solid var(--#{$prefix}core-default-color-status-info-darker);
        }
        .global-alert-icon {
            // aligning with text
            padding-top: 1.375rem;
            // temporary change to align icon to center
            align-self: center;
        }
        .qld-global-alert-main {
            // Padding requires 64px in space between alert icon and left border; minus (24px(1.5rem) gutter + 16px padding) = 24px (1.5rem)
            padding-left: 1.5rem;
            .global-alert-content {
                padding-left: 1rem; // Also: 16px
            }
        }
        
    }
}