// QGDS QOL In-page Navigation
// extends Bootstrap Nav: https://getbootstrap.com/docs/5.2/components/navs-tabs/#sass-variables

// QGDS specific In-page Navigation variables.
.qld-inpage-nav {
    --#{$prefix}inpage-nav-padding-left: 1.5rem;
    --#{$prefix}inpage-nav-title-padding-left: $nav-link-padding-x;
    --#{$prefix}inpage-nav-link-padding-left: $nav-link-padding-x;
    --#{$prefix}inpage-nav-link-padding-y: 0.25rem;
    --#{$prefix}inpage-nav-border-color: var(--#{$prefix}brand-primary);
}

.dark .qld-inpage-nav,
.dark-alt .qld-inpage-nav {
    --#{$prefix}inpage-nav-border-color: var(--#{$prefix}dark-action-primary);

    .nav {
        --#{$prefix}nav-link-color: var(--#{$prefix}white);
        --#{$prefix}nav-link-hover-color: var(--#{$prefix}brand-accent);
    }
}

// General styling rules.
.qld-inpage-nav {
    border-left: 4px solid var(--#{$prefix}inpage-nav-border-color);
    padding-left: var(--#{$prefix}inpage-nav-padding-left);

    ul.nav {
        display: flex;
        flex-direction: column;
        padding-left: 0;
		margin-top: 1rem;
    }

    .nav-title {
        font-size: 1rem;
        line-height: 1.25;
        margin-bottom: 0;
        padding-left: var(--#{$prefix}inpage-nav-title-padding-left);
    }

    .nav-item {
        .nav-link {
            padding-top: var(--#{$prefix}inpage-nav-link-padding-y);
            padding-bottom: var(--#{$prefix}inpage-nav-link-padding-y);
            padding-left: var(--#{$prefix}inpage-nav-title-padding-left);
            color: var(--#{$prefix}nav-link-color);
        }

        &:first-child .nav-link {
            padding-top: 0;
        }
    }
}
